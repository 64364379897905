import BaseModel from "./BaseModel";
import { BaseModelJson } from "../types/BaseModelJson.type";
import { CartOrderJson, CartPreOrderJson } from "@/types/Cart.type";
import { OrderJson } from "@/types/Orders.type";
import {
	PaymentCouponDetail,
	PaymentMethodDetailUpdate,
} from "@/types/Payment.type";
import { PromotionJson } from "@/types/Promotion.type";
import { DeliveryDetail } from "@/types/Invoice.type";

// Default data object
const defaultDataProduct: OrderJson = {
	company_id: 0,
	creator_id: 0,
	id: "",
	code: "",
	store_id: 0,
	customer_id: 0,
	browser_id: "",
	coupons: [],
	cancel_reason: 0,
	price_sell: 0,
	price_shipping: 0,
	price_discount: 0,
	price_discount_coupon: 0,
	price_final: 0,
	price_cost: 0,
	price_deposit: 0,
	price_debt: 0,
	price_tax: 0,
	promotion_id: 0,
	promotions: [],
	contact_email: "",
	billing_full_name: "",
	billing_phone: "",
	billing_email: "",
	billing_address: "",
	billing_ward: 0,
	billing_district: 0,
	billing_province: 0,
	billing_country: 0,
	billing_company: "",
	billing_sub_sub_region_id: 0,
	billing_sub_region_id: 0,
	billing_region_id: 0,
	shipping_fullname: "",
	shipping_phone: "",
	shipping_address: "",
	shipping_ward: 0,
	shipping_district: 0,
	shipping_province: 0,
	shipping_country: 0,
	shipping_company: "",
	warehouse_id: 0,
	shipping_carrier: 0,
	product_receipt_id_list: [],
	cashflow_receipt_id_list: [],
	tax_invoice_id: 0,
	quantity: 0,
	note: "",
	cod_amount: 0,
	status: 0,
	vnpay_status: false,
	tag: "",
	ecom_platform_id: 0,
	ecom_platform_type: 0,
	ecom_platform_order_id: "",
	ecom_platform_invoice_id: "",
	date_arrived: 0,
	date_created: 0,
	date_modified: 0,
	payment_method: 0,
	resource_type: 0,
	resource_id: "",
	resource_number: "",
	order_detail: "",
	order_description: "",
	kiotviet_code: "",
	details: {
		data: [],
		total: 0,
	},
	total_payment: 0,
	debt: 0,
	payments: [],
	has_invoice: false,
	order_custom_discount: 0,
	billing_firstname: "",
	billing_lastname: "",
	coupondetail: null,
	date_completed: 0,
	date_deleted: 0,
	deposit: 0,
	ipaddress: 0,
	is_deleted: 0,
	kiotviet_id: "",
	note_invoice: "",
	promotion_detail: [],
	shipping_firstname: "",
	shipping_lastname: "",
	type: 0,
	delivery_detail: [],
	billing_fullname: "",
	order_id: 0,
	item_discount  : 0,
	order_discount : 0
};

class CartOrderModel extends BaseModel implements BaseModelJson<OrderJson> {
	billing_address: string = defaultDataProduct.billing_address;
	billing_country: number = defaultDataProduct.billing_country;
	billing_district: number = defaultDataProduct.billing_district;
	billing_fullname: string = defaultDataProduct.billing_full_name;
	billing_phone: string = defaultDataProduct.billing_phone;
	billing_province: number = defaultDataProduct.billing_province;
	billing_ward: number = defaultDataProduct.billing_ward;
	billing_email: string = defaultDataProduct.billing_email;
	billing_firstname: string = defaultDataProduct.billing_firstname;
	billing_lastname: string = defaultDataProduct.billing_lastname;
	browser_id: string = defaultDataProduct.browser_id;
	cod_amount: number = defaultDataProduct.cod_amount;
	code: string = defaultDataProduct.code;
	company_id: number = defaultDataProduct.company_id;
	coupondetail: PaymentCouponDetail[] | null = defaultDataProduct.coupondetail;
	creator_id: number = defaultDataProduct.creator_id;
	customer_id: number = defaultDataProduct.customer_id;
	date_arrived: number = defaultDataProduct.date_arrived;
	date_completed: number = defaultDataProduct.date_completed;
	date_created: number = defaultDataProduct.date_created;
	date_deleted: number = defaultDataProduct.date_deleted;
	date_modified: number = defaultDataProduct.date_modified;
	debt: number = defaultDataProduct.debt;
	deposit: number = defaultDataProduct.deposit;
	details: { total: number; data: CartOrderJson[] } =
		defaultDataProduct.details;
	id: string = defaultDataProduct.id;
	ipaddress: number = defaultDataProduct.ipaddress;
	is_deleted: number = defaultDataProduct.is_deleted;
	kiotviet_code: string = defaultDataProduct.kiotviet_code;
	kiotviet_id: string = defaultDataProduct.kiotviet_id;
	note: string = defaultDataProduct.note;
	note_invoice: string = defaultDataProduct.note_invoice;
	payments: PaymentMethodDetailUpdate[] = defaultDataProduct.payments;
	price_cost: number = defaultDataProduct.price_cost;
	price_discount: number = defaultDataProduct.price_discount;
	price_discount_coupon: number = defaultDataProduct.price_discount_coupon;
	price_final: number = defaultDataProduct.price_final;
	price_sell: number = defaultDataProduct.price_sell;
	price_shipping: number = defaultDataProduct.price_shipping;
	price_tax: number = defaultDataProduct.price_tax;
	promotion_detail: PromotionJson[] = defaultDataProduct.promotion_detail;
	promotion_id: number = defaultDataProduct.promotion_id;
	quantity: number = defaultDataProduct.quantity;
	shipping_address: string = defaultDataProduct.shipping_address;
	shipping_carrier: number = defaultDataProduct.shipping_carrier;
	shipping_country: number = defaultDataProduct.shipping_country;
	shipping_district: number = defaultDataProduct.shipping_district;
	shipping_fullname: string = defaultDataProduct.shipping_fullname;
	shipping_phone: string = defaultDataProduct.shipping_phone;
	shipping_province: number = defaultDataProduct.shipping_province;
	shipping_ward: number = defaultDataProduct.shipping_ward;
	shipping_firstname: string = defaultDataProduct.shipping_firstname;
	shipping_lastname: string = defaultDataProduct.shipping_lastname;
	status: number = defaultDataProduct.status;
	store_id: number = defaultDataProduct.store_id;
	tag: string = defaultDataProduct.tag;
	total_payment: number = defaultDataProduct.total_payment;
	type: number = defaultDataProduct.type;
	warehouse_id: number = defaultDataProduct.warehouse_id;
	delivery_detail: DeliveryDetail[] = defaultDataProduct.delivery_detail;
	has_invoice: boolean = defaultDataProduct.has_invoice;
	item_discount: number = defaultDataProduct.item_discount;
	order_discount: number = defaultDataProduct.order_discount;

	constructor(json: OrderJson) {
		super();
		Object.assign(this, { ...defaultDataProduct, ...json });
	}

	static getDefaultData(): OrderJson {
		return { ...defaultDataProduct };
	}

	toJson(): OrderJson {
		return JSON.parse(JSON.stringify(this));
	}
}

export default CartOrderModel;
