"use client";
import ProductModel from "@/common/models/ProductModel";
import { getListProduct } from "@/lib/api/shop/products";
import {
	ProductActualQuantity,
	ProductJson,
	ProductTagJson,
} from "@/types/Product.type";
import {
	converGroupToGroupVariant,
	mapActualQuantity,
	mapPromotionPrice,
} from "@/utils/product";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
	PromotionDiscountType,
	PromotionGroupType,
} from "@/types/Promotion.type";
import Website from "@/common/constants/webstite";
import { CalcPricePromotionJson } from "@/types/Cart.type";
import useStoreId from "./useStoreId";
type Props = {
	product: ProductJson;
	variantsDefauilt?: ProductJson[];
	variantFromTagsDefauilt?: VariantsFromTags[];
};

export type VariantsFromTags = Pick<ProductTagJson, "name"> & {
	data: ProductJson[];
};
export default function useVariantsV2({
	product,
	variantFromTagsDefauilt,
	variantsDefauilt,
}: Props) {
	const [variants, setVariants] = useState<Array<ProductJson>>([]);
	const [variantFromTags, setVariantFromTags] = useState<VariantsFromTags[]>(
		[]
	);
	const { data: storeId } = useStoreId();

	const [variantActive, setVariantActive] = useState<ProductJson>(
		ProductModel.getDefaultData()
	);

	// const [errorQuantity, setErrorQuantity] = useState<string[]>([]);

	// const [loadingQuantity, setLoadingQuantity] = useState(true);

	const queryClient = useQueryClient();

	const group = product.related.filter(
		(g) => g.type === "PRODUCT STRUCT" && g.items.length > 0
	);

	const [isLoading, setIsLoading] = useState(true);

	const { data: products, error } = useQuery({
		queryKey: ["product-variants", product.id],
		queryFn: async () => {
			try {
				const ids = group.flatMap((gr) => gr.items).join(",");
				if (!ids.length) {
					throw new Error(
						`no variant product id ${product.id} - sku ${product.sku} in useVariant`
					);
				}
				const { data: res } = await getListProduct({
					list_product_id: group.flatMap((gr) => gr.items).join(","),
					page: 1,
					show_promotion_price: 1,
					store_id: storeId,
				});
				const items = res.items;
				if (!items.length) {
					throw new Error(
						`no variant product id ${product.id} - sku ${product.sku} in useVariant`
					);
				}
				return items;
			} catch (error) {
				setIsLoading(false);
				throw error;
			}
		},
		staleTime: Website.TIMEOUT.VARIANTS,
		enabled: !variantsDefauilt || !group.length || !storeId,
		retry: 3,
		retryDelay: 1000,
	});

	function refetch() {
		queryClient.invalidateQueries({
			queryKey: ["product-variants", product.id],
		});
	}

	// async function getActualQuantityAndUpdate(variants: ProductJson[]) {
	//   if (!variants.length) return;
	//   try {
	//     setLoadingQuantity(true);
	//     const { data: actuals } = await getActualQuantity({
	//       product_ids: variants.map((pro) => pro.id).join(","),
	//     });
	//     const variantsNew = mapActualQuantity(variants, actuals);
	//     const variantGrops: VariantsFromTags[] = converGroupToGroupVariant(
	//       group,
	//       variantsNew
	//     );

	//     setStateGroup(variantsNew, variantGrops);
	//   } catch (error) {
	//     setErrorQuantity([JSON.stringify(error)]);
	//   } finally {
	//     setLoadingQuantity(false);
	//   }
	// }

	useEffect(() => {
		if (products && products.length) {
			setIsLoading(true);

			const caclPricePreview: CalcPricePromotionJson = products.reduce(
				(pre: CalcPricePromotionJson, variant) => {
					const promotion = variant.promotions.find(
						(pro) =>
							pro.group === PromotionGroupType.seasonal &&
							pro.discount_type === PromotionDiscountType.PRODUCT
					);
					if (promotion) {
						pre.push({
							discount: promotion.discount_value,
							item_quantity: 1,
							price_final: promotion.promotion_price,
							price_Unit: variant.price,
							product_id: variant.id,
						});
					}
					return pre;
				},
				[]
			);
			const actu: ProductActualQuantity[] = products.map((pro) => {
				return {
					actual_quantity: pro.quantity,
					product_id: pro.id,
					quantity: pro.quantity,
				};
			});
			const variantsData = mapPromotionPrice(products, caclPricePreview);
			const variantsNew = mapActualQuantity(variantsData, actu);

			const variantGrops: VariantsFromTags[] = converGroupToGroupVariant(
				group,
				variantsNew
			);
			setStateGroup(variantsNew, variantGrops);
			setIsLoading(false);
		}
	}, [JSON.stringify(products)]);

	useEffect(() => {
		if (variantsDefauilt && variantFromTagsDefauilt) {
			setStateGroup(variantsDefauilt, variantFromTagsDefauilt);
			// getActualQuantityAndUpdate(variantsDefauilt);
		}
	}, [variantsDefauilt]);

	function setStateGroup(
		variants: ProductJson[],
		variantFromTags: VariantsFromTags[]
	) {
		setVariants(variants);
		setVariantFromTags(variantFromTags);
		const listFirst = variantFromTags?.[0].data;
		const proDefauilt =
		variants.find((item) => item.id === product.id) || ((listFirst && listFirst.length) ? listFirst[0] : variants[0])
		if (proDefauilt) {
			setVariantActive(proDefauilt);
		}


		setIsLoading(false);
	}

	function handleActiveVariant(variant: ProductJson) {
		setVariantActive(variant);
	}

	return {
		isLoading,
		error,
		variantActive,
		variantFromTags,
		variants,
		handleActiveVariant,
		refetch,
	};
}
